
































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import CollForm from "./components/CollForm.vue";
import CollTable from "./components/CollTable.vue";
import {Image} from "@/store/types";

export default Vue.extend({
  name: "OfferImagesPage",

  components: {
    FullScreenDialog,
    CollForm,
    CollTable
  },

  data: () => ({
    fullScreenDialog: false,
    image: null as unknown | Image,
  }),

  computed: {
    ...mapGetters({
      images: "offerImagesModule/getImages",
      total: "offerImagesModule/getTotal",
      loading: "offerImagesModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("offerImagesModule/fetch", { sort: { created_at: -1 } });
  },

  methods: {
    editCol(image: Image): void {
      this.image = image;
      this.openFullScreenDialog();
    },

    onUpdate(image: Image): void {
      this.$store.dispatch("offerImagesModule/update", image);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("offerImagesModule/fetch", {
        ...payload,
        sort: { created_at: -1 }
      });
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.image = null;
    },
  }
})
