var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coll-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"loading":_vm.loading,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"height":"50px","width":"50px","margin":"5px"},attrs:{"src":item.url,"aspect-ratio":"1"}})]}},{key:"item.dimension",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.width)+"x"+_vm._s(item.height)+" ")]}},{key:"item.is_real",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.real_product ? 'SIM' : 'NÃO')+" ")]}},{key:"item.is_without_bg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.removed_bg ? 'SIM' : 'NÃO')+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticStyle:{"width":"40px","margin-left":"auto"},attrs:{"inset":""},on:{"click":function($event){return _vm.onUpdate(Object.assign({}, {active: !item.active}, item))}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue","disabled":item.archived},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }